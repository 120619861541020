<template>
  <section>
    <p class="title is-4">Informatie van de aanvragende entiteit</p>

    <b-field label="Entiteit"
             message="Gelieve de Entiteit en Projectnaam naam steeds in het email onderwerp te vermelden bij alle communicatie.">
      <b-input v-model="content.entity_name" :disabled="read" name="entiteit" expanded required></b-input>
    </b-field>
    <b-field label="Entiteitscode">
      <b-input v-model="content.entity_code" :disabled="read" name="entiteitscode"></b-input>
    </b-field>
    <b-field label="Projectnaam">
      <b-input v-model="content.project_name" :disabled="read" name="projectnaam" expanded
               placeholder="bv. HFB <mijn_project> – draft dossier"></b-input>
    </b-field>
    <b-field label="Toepassings-nummer" message="Enkel indien HB+ (CMDB2)">
      <b-input v-model="content.toepassings_num" :disabled="read" name="projectnaam" expanded></b-input>
    </b-field>
    <b-field label="Externe partijen"
             message="Indien andere partij betrokken, definieer (Leverancier, Onderaannemer, …)">
      <b-input v-model="content.external_parties" :disabled="read" name="externe_partijen" expanded></b-input>
    </b-field>
    <b-field label="Gewenste Installatiedatum">
      <b-datepicker
          v-model="content.installatie"
          :disabled="read"
          placeholder="Klik om te selecteren.."
          icon="calendar-today"
          position="is-top-right"
          trap-focus>
      </b-datepicker>
    </b-field>

    <b-field class="has-text-right"><!-- Label left empty for spacing -->
      <p class="control">
        <button @click="submit" class="button is-primary">
          Volgende
        </button>
      </p>
    </b-field>

    <br>

  </section>

</template>

<script>
export default {
  name: "algemene-gegevens",
  props: {
    submission_id: Number,
    read: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: {}

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchSubmissionDetails", this.submission_id).then(response => {
        let result = response.data.data
        if (result._1_requesting_entity) {
          this.content = JSON.parse(JSON.stringify(result._1_requesting_entity))
          if (this.content.installatie) {
            this.content.installatie = new Date(this.content.installatie)
          }
        } else {
          this.content = {}

        }
      })
    },

    submit() {
      if (this.read) {
        this.$emit("next")
        return
      }
      this.$store.dispatch("storeSubmissionData", {
        'id': this.submission_id,
        'data': {_1_requesting_entity: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    }

  }
}
</script>

<style scoped>

</style>