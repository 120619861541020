<template>
  <div>
    <p class="title is-4">Bijkomende informatie / extra's</p>

    <section class="content">

      <h4>Documentatie</h4>
      <p>
        De leverancier die gebruik wenst te maken van de VO netwerkinfrastructuur is verantwoordelijk voor het
        documenteren van de omgeving en aanleveren van Technische fiches voor de gebruikte randapparatuur.
      </p>
      <h4>Integratie ACM</h4>
      <p>
        Voor de integratie met ACM-IDM verwijzen we naar volgende documentatie:
        <a href="https://vlaamseoverheid.sharepoint.com/:p:/r/sites/afb/ICT_Klanten/acmidm/integraties/_layouts/15/Doc.aspx?sourcedoc=%7B80fc08b9-42e8-4e2d-b223-0640443cc7b6%7D&action=default">
          <b-tag type="is-info is-light">Bekijk Documentatie</b-tag>
        </a>
      </p>
      <h4>Incident Management</h4>
      <p>
        De aanvrager bezorgt zijn incident- en servicedesk contactgegevens aan de ICT dienstverlener, zodat alle
        incidenten via 1 centraal nummer gelogd en gedispatched kunnen worden. Dit is telefonisch via 02 55 3900 of
        39000 via verkorte nummering, dan wel via email op <a href="mailto:servicedesk@vlaanderen.be">servicedesk@vlaanderen.be</a>.
      </p>
      <p>
        Hier zal geverifieerd worden of er sprake is van een storing op het netwerk dan wel of er problemen zijn met de
        betreffende toepassing en/of systeem
      </p>
      <p>
        In geval dat er problemen op het netwerk of gerelateerde netwerkcomponenten zijn is het de verantwoordelijkheid
        van de ICT dienstverlener deze op te lossen conform de contractueel overeengekomen processen en SLA.
        Indien de oorzaak die toe te schrijven zijn aan de betreffende toepassing of systeem zal de Het Faciliair
        Bedrijf zelf contact op moeten nemen met de betreffende leverancier. Het is de verantwoordelijkheid van deze
        leverancier het incident dan verder op te lossen.
      </p>
      <p>
        Om dit proces zo correct mogelijk te laten verlopen is het noodzakelijk dat de leverancier:
      </p>
      <ol>
        <li>
          <b>Tijdens kantooruren beschikbaar is.</b> Alleen op die manier kan, in geval van een niet eenduidige
          rootcause
          analyse, overleg worden gepleegd met de leverancier om de rootcause verder te analyseren
        </li>
        <li>
          De betreffende leverancier dient de <b>correcte contactgegevens</b> door te geven en is verantwoordelijk dat
          deze
          contact gegevens steeds actueel zijn.
        </li>
      </ol>

      <h4>Configuratiemanagement / CMDB2 (nieuwe toestellen)</h4>
      <p>
        Voor de aansluiting van systemen via het AFM netwerk dient de CMDB2 door de ICT dienstverlener volledig ingevuld
        te kunnen worden zodanig dat de nieuwe toepassing/systeem gekend is in de ondersteunende systemen en tools en er
        bij problemen adequaat kan worden gereageerd.
      </p>

      <h4>GDPR</h4>
      <p>
        In geval van nieuwe leveranciers dient de leverancier vooraf akkoord te gaan met de GDPR vereisten die door Het
        Facilitair Bedrijf worden gesteld in het kader van de contractuele afspraken met leveranciers.
      </p>

    </section>
  </div>

</template>

<script>
export default {
  name: "bijkomende-informatie",
  props: ['submission_id'],

  data() {
    return {
      content: {}

    }
  },

  mounted() {
  },

}
</script>

<style scoped>

</style>