<template>
  <div class="container" style="min-height: 80vh; max-width: 1200px">
    <div class="columns">
      <div class="column is-3">
        <div class="box">

          <b-steps v-model="currentstep" type="is-primary" :has-navigation="false" :vertical="true"
                   label-position="right" :destroy-on-hide="true">
            <b-step-item :step="0" :clickable="true" label="Algemene Gegevens" icon="account">
            </b-step-item>
            <b-step-item :step="1" :clickable="true" label="Contactgegevens" icon="account">
            </b-step-item>
            <b-step-item :step="2" :clickable="true" label="Dienstverlening" icon="account">
            </b-step-item>
            <b-step-item class="has-text-centered" :step="3" :clickable="true" label="Toestellen"
                         icon="server-network">
            </b-step-item>

            <b-step-item class="has-text-centered" :step="4" :clickable="true" label="Finalisatie" icon="check">
            </b-step-item>

          </b-steps>
        </div>


      </div>
      <div class="column">

        <div class="box" v-if="currentstep === 0">
          <div class="content article-body">
<!--            <b-message>De aanvragende Entiteit dient alle relevante informatie aangaande de te connecteren toestellen-->
<!--              tijdig en volledig op te leveren aan de hand van deze template alvorens de onboarding van de toestellen-->
<!--              effectief in productie kan worden gezet.-->
<!--            </b-message>-->
<!--            <b-message>Deze template geldt als aanvraag en omvat tevens de vraagstelling naar MAB (Mac Authentication-->
<!--              Bypass) en Firewall regels (ACD). Er dient naast deze template dus geen aparte aanvraag gelanceerd te-->
<!--              worden voor MAB en of ACD.-->
<!--            </b-message>-->
            <algemene-gegevens :read="read" :submission_id="id" v-on:next="next"/>
          </div>
        </div>
        <div class="box" v-if="currentstep === 1">
          <div class="content article-body">
            <contactgegevens :read="read" :submission_id="id" v-on:next="next"/>
          </div>
        </div>
        <div class="box" v-if="currentstep === 2">
          <div class="content article-body">
            <huidige-dienstverlening :read="read" :submission_id="id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 3">
          <div class="content article-body">
            <p class="title is-4">Aan te vragen toestellen</p>
            <b-notification type="is-primary is-light"> Per ‘type’ toestel dient een aparte aanvraag ingediend
              te worden. <br> Toestellen van hetzelfde type hebben allemaal dezelfde ‘netwerk karakteristieken’
            </b-notification>

            <device-overview :submission_id="id">

            </device-overview>
            <br><br>

            <hr>
            <div class="has-text-right">
              <b-button type="is-primary" @click="next">Volgende</b-button>
            </div>
          </div>
        </div>

        <div class="box" v-if="currentstep === 4">
          <div class="content article-body">
            <bijkomende-informatie :submission_id="id"></bijkomende-informatie>
            <hr>
            <div class="has-text-right">
              <b-button type="is-primary" @click="submitSubmission">Aanvraag indienen</b-button>
            </div>
          </div>
        </div>

        <div class="box" v-if="currentstep === 5">
          <br>
          <b-icon type="is-success" custom-size="mdi-48px" icon="check-circle"></b-icon>
          <p class="title pt-2">Aanvraag ingediend! </p>
          <p>We bekijken je aanvraag en houden je op de hoogte van de status.
            Je kan je huidige inzending nog steeds bekijken en wijzigen.</p>
          <br>
          <b-button @click="$router.push({ name:'submission.overview'})" type="is-primary" outlined>Mijn
            inzendingen
          </b-button>
        </div>

      </div>
    </div>

  </div>


</template>

<script>
import AlgemeneGegevens from "@/components/entiteit/algemene-gegevens";
import DeviceOverview from "@/components/toestellen/DeviceOverview";
import BijkomendeInformatie from "@/components/entiteit/bijkomende-informatie";
import Contactgegevens from "@/components/entiteit/contactgegevens";
import HuidigeDienstverlening from "@/components/entiteit/huidige-dienstverlening";

export default {
  name: "Onboarding.vue",
  components: {
    HuidigeDienstverlening,
    Contactgegevens,
    BijkomendeInformatie,
    DeviceOverview,
    AlgemeneGegevens
  },

  props: {
    steps: Number,
    read: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentstep: 0

    }
  },
  mounted() {
    if (this.step) {
      this.currentstep = this.step;
    }

    this.$store.dispatch("fetchSubmissionDetails", this.id)
        .then(response => {
          this.$store.dispatch('setTitle', {
            message: "",
            back: true,
            stack: [
              {message: response.data.name},
            ]
          })

        })

    this.$store.dispatch('setTitle', {
      message: "Onboarding",
      back: true,
      stack: []
    })
  },

  computed: {
    id() {
      return parseInt(this.$route.params.id)
    },
  },
  methods: {

    next() {
      this.currentstep++
    },

    submitSubmission() {
      this.$store.dispatch("submitSubmission", this.id).then(() => {
        this.next()
      }).catch(error => {
        console.log(error)
        this.$buefy.toast.open({
          message: error.data.message,
          type: 'is-danger'
        })

      })
    },
  }
}
</script>

<style scoped>

.articles .content {
  line-height: 1.9;
}

.author-image {
  position: absolute;
  top: -30px;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  border: 3px solid #ccc;
  border-radius: 50%;
}

.media-center {
  display: block;
  margin-bottom: 1rem;
}

.header-media-content {
  margin-top: 3rem;
}

.article, .promo-block {
  margin-top: 6rem;
}

div.field-label {
  flex-grow: 2;
}

div.column.is-8:first-child {
  padding-top: 0;
  margin-top: 0;
}

.articles {
  margin: 5rem 0;
  margin-top: -295px;
  padding-bottom: 200px;

}

.promo-block .container {
  margin: 1rem 5rem;
}

.articles .content {
  line-height: 1.5;
  margin-left: 15px;
  margin-right: 10%;
}

.article-title {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 2;
}

.article-subtitle {
  color: #909AA0;
  margin-bottom: 3rem;
}

.article-body {
  line-height: 1;
  margin: 1.5rem 1.5rem;
}

.promo-block .container {
  margin: 1rem 5rem;
}
</style>