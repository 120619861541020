<template>
  <div>
    <section>
      <p class="title is-4">Contactgegevens</p>

      <b-field grouped message="U kan nog extra gegevens toevoegen in de velden hieronder.">
        <b-field label="Naam" expanded>
          <b-input required v-model="content.naam" :disabled="read" icon="account"
                   placeholder="Contactpersoon.."></b-input>
        </b-field>
        <b-field label="Functie" expanded>
          <b-input v-model="content.functie" :disabled="read" icon="account-tie"
                   placeholder="Verantwoordelijke"></b-input>
        </b-field>
      </b-field>

      <b-field grouped>
        <b-field label="Telefoonnr" expanded>
          <b-input required v-model="content.telefoon" :disabled="read" icon="phone" placeholder="+324..."></b-input>
        </b-field>
        <b-field label="Emailadres" expanded>
          <b-input required v-model="content.email" :disabled="read" icon="email" type="email"
                   placeholder="..@vlaanderen.be"></b-input>
        </b-field>
      </b-field>

      <b-field label="Toegangsprocedure"
               message="Beschrijf hier welke gegevens er nodig zijn om toegang te krijgen tot het gebouw.">
        <b-input v-model="content.toegangsprocedure" :disabled="read" type="textarea"></b-input>
      </b-field>
      <b-field label="Extra gegevens" message="Indien nodig">
        <b-input v-model="content.extra" :disabled="read" type="textarea"></b-input>
      </b-field>


      <b-field class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
export default {
  name: "contactgegevens",
  props: {
    submission_id: Number,
    read: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      content: {}

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchSubmissionDetails", this.submission_id).then(response => {
        let result = response.data.data
        if (result._contactgegevens) {
          this.content = JSON.parse(JSON.stringify(result._contactgegevens))

        } else {
          this.content = {}

        }
      })
    },

    submit() {
      if (this.read) {
        this.$emit("next")
        return
      }
      this.$store.dispatch("storeSubmissionData", {
        'id': this.submission_id,
        'data': {_contactgegevens: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    }

  }
}
</script>

<style scoped>

</style>