<template>

  <div class="container">

    <div v-for="device in devices" v-bind:key="device.id" class="box" style="cursor: pointer">
      <article
          @click="$router.push({ name:'device.onboarding', params: {submission_id: submission_id, device_id: device.id}})"
          class="media" style="padding-top: 0; border-top: 0">
        <div class="media-content">
          <div class="content">
            <div class="level">
              <div class="level-left">
                <span class="is-size-5 has-text-weight-bold level-item">{{ device.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="media-right">
          <b-button @click.stop="confirmDelete(device, submission_id)" size="is-small" type="is-danger" outlined
                    icon-right="delete"></b-button>
        </div>
      </article>
    </div>


    <div class="box" style="cursor: pointer">
      <article @click.stop="createModal" class="media" style="padding-top: 0; border-top: 0">
        <div class="media-content ">
          <div class="content has-text-centered">
            <b-icon icon="plus-circle" class="pr-3"></b-icon>
            <span class="is-size-5 has-text-weight-bold ">Voeg toestel toe</span>
          </div>
        </div>
      </article>
    </div>


  </div>

</template>

<script>

export default {
  name: "DeviceOverview.vue",
  props: ["submission_id"],

  data() {
    return {
      devices: []
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchSubmissionDevices", this.submission_id).then(response => {
        this.devices = response.data
      })
    },
    createModal() {
      this.$buefy.dialog.prompt({
        message: `Geef een naam aan je reeks toestellen`,
        inputAttrs: {
          placeholder: 'IP phones gebouw 1',
          value: '',
          min: 1
        },
        trapFocus: true,
        onConfirm: (value) => this.createdevice(value)
      })
    },

    createdevice(value) {
      this.$store.dispatch("newDevice", {
        data: {
          'name': value,
        },
        submission_id: this.submission_id
      }).then((response) => {
        this.$buefy.snackbar.open({
          message: 'Toestel aanvraag aangemaakt als concept',
          type: 'is-success',
          position: 'is-top'
        })
        this.$router.push({
          name: 'device.onboarding',
          params: {device_id: response.data.id, submission_id: this.submission_id}
        })

      })
    },

    confirmDelete(device, submission_id) {
      this.$buefy.dialog.confirm({
        title: 'Verwijder toestel',
        message: `Ben u zeker om de ingevulde gegevens voor toestel ${device.name} te <b>verwijderen?</b> Deze actie kan niet ongedaan gemaakt worden.`,
        confirmText: 'Verwijder',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch("deleteDevice", {
            device_id: device.id,
            submission_id: submission_id
            // eslint-disable-next-line no-unused-vars
          }).then(res => {this.fetchData()})
          this.$buefy.toast.open('Toestel verwijderd')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>