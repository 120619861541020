<template>
  <div>
    <section>
      <p class="title is-4">Huidige Netwerk Dienstverlening</p>

      <p>Bij wie worden momenteel de netwerkdiensten verleend?</p>

      <b-field>
        <b-radio :disabled="read" v-model="content.huidige_dienstverlening" native-value="netwerkdiensten_hfb" expanded>
          <b>Netwerk Diensten HFB</b>
        </b-radio>
      </b-field>

      <div class="box pl-5" v-if="content.huidige_dienstverlening === 'netwerkdiensten_hfb'">

        <b-field>
          <b-radio :disabled="read" v-model="content.hfb.gid" native-value="is_gid" expanded>
            Entiteit is ‘GID’ klant bij HFB
          </b-radio>
        </b-field>

        <div class="box pl-5" v-if="content.hfb.gid === 'is_gid'">
          <b-message type="is-primary">
            Deze gemeenschappelijke ICT-diensten worden aangeboden voor alle entiteiten van de Vlaamse overheid alsook
            voor de lokale besturen en bestaan uit een breed gamma van ICT-diensten waaronder een geïntegreerd geheel
            van werkplekken, IAM- en directory bouwstenen, beveiligings- en integratiebouwstenen, netwerkdiensten, etc.
            Deze wordt momenteel verzorgd door de VO ICT dienstverlener HB+, een tijdelijke handelsvereniging gevormd
            door DXC en Proximus. Hoewel GID een zeer breed begrip is in termen van dienstverlening, wordt hiermee
            vanuit netwerkdiensten voornamelijk gerefereerd naar het ‘bureautica-netwerk’ van de VO.
          </b-message>
        </div>

        <b-field>
          <b-radio :disabled="read" v-model="content.hfb.gid" native-value="geen_gid" expanded>
            Entiteit is geen GID maar neemt ‘partieel’ Netwerk Diensten af
          </b-radio>
        </b-field>
        <div class="box pl-5" v-if="content.hfb.gid === 'geen_gid'">
          <b-field>
            <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-wan" expanded>
              VO-WAN diensten
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-lan-wlan" expanded>
              VO-LAN/WLAN diensten
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-isp" expanded>
              VO-ISP ('native' internet)
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-security" expanded>
              VO Netwerk Security
            </b-checkbox>
          </b-field>

          <div class=" pl-5" v-if="content.hfb.geen_gid_options.includes('vo-security')">
            <b-field>
              <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-dedicated-fw" expanded>
                Dedicated (virtuele) Firewall
              </b-checkbox>
            </b-field>
            <div class=" pl-5" v-if="content.hfb.geen_gid_options.includes('vo-sec-dedicated-fw')">
              <b-field>
                <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-dedicated-fw-eigen-beheer"
                            expanded>
                  in eigen beheer
                </b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-dedicated-fw-beheer-hfb"
                            expanded>
                  in beheer van HFB
                </b-checkbox>
              </b-field>
              <br>
            </div>
            <b-field>
              <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-fw-proxy" expanded>
                Forward Proxy - SSL Inspectie
              </b-checkbox>
            </b-field>
            <div class=" pl-5" v-if="content.hfb.geen_gid_options.includes('vo-sec-fw-proxy')">
              <b-field>
                <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-fw-proxy-eigen-beheer" expanded>
                  in eigen beheer
                </b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-fw-proxy-beheer-hfb" expanded>
                  in beheer van HFB
                </b-checkbox>
              </b-field>
              <br>
            </div>
            <b-field>
              <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-r-proxy" expanded>
                R-Proxy/LB/WAF
              </b-checkbox>
            </b-field>
            <div class=" pl-5" v-if="content.hfb.geen_gid_options.includes('vo-sec-r-proxy')">
              <b-field>
                <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-r-proxy-eigen-beheer" expanded>
                  in eigen beheer
                </b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-sec-r-proxy-beheer-hfb" expanded>
                  in beheer van HFB
                </b-checkbox>
              </b-field>
              <br>
            </div>
            <br>
          </div>
          <b-field>
            <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-dns" expanded>
              VO-DNS (Authoritatief)
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox :disabled="read" v-model="content.hfb.geen_gid_options" native-value="vo-partner access" expanded>
              VO Partner/Cloud access (Partner, AWS, Azure)
            </b-checkbox>
          </b-field>

        </div>
        <b-message type="is-primary">
          Deze Diensten staan beschreven in het Service Portfolio document van de HFB Netwerk Diensten
        </b-message>
      </div>

      <b-field>
        <b-radio :disabled="read" v-model="content.huidige_dienstverlening" native-value="andere_diensten_hfb" expanded>
          <b>Andere diensten HFB</b>
        </b-radio>
      </b-field>

      <div class="box pl-5" v-if="content.huidige_dienstverlening === 'andere_diensten_hfb'">
          <b-field>
            <b-checkbox :disabled="read" v-model="content.andere.options" native-value="vo_webidm" expanded>
              Entiteit is gekend in VO WebIDM
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox :disabled="read" v-model="content.andere.options" native-value="vo_alfa_ad" expanded>
              Entiteit is gekend in VO Alfa AD
            </b-checkbox>
          </b-field>

      </div>

      <b-field>
        <b-radio :disabled="read" v-model="content.huidige_dienstverlening" native-value="geen_diensten_hfb" expanded>
          <b>Entiteit neemt nog geen netwerk diensten van HFB af</b>
        </b-radio>
      </b-field>


      <b-field class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
export default {
  name: "huidige-dienstverlening",
  props: {
    submission_id: Number,
    read: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: {
        huidige_dienstverlening: "",
        hfb: {
          geen_gid_options: [],
        },
        andere: {
          options: []
        }
      }

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchSubmissionDetails", this.submission_id).then(response => {
        let result = response.data.data
        if (result._huidige_dienstverlening) {
          this.content = JSON.parse(JSON.stringify(result._huidige_dienstverlening))

        }
      })
    },

    submit() {
      if (this.read) {
        this.$emit("next")
        return
      }
      this.$store.dispatch("storeSubmissionData", {
        'id': this.submission_id,
        'data': {_huidige_dienstverlening: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    }

  }
}
</script>

<style scoped>

</style>